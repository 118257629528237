.quienes-somos-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 6rem;
  padding-right: 40px;
}

.home-01 {
  box-shadow: 10px 10px 5px #ccc;
  -moz-box-shadow: 10px 10px 5px #ccc;
  -webkit-box-shadow: 10px 10px 5px #ccc;
  -khtml-box-shadow: 10px 10px 5px #ccc;
  width: 100%;
}

.quienes-somos-image-container {
  width: 50%;
}

.quienes-somos-information-container {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  width: 50%;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
}

.quienes-somos-title {
  color: #0691d1;
  font-size: 100px;
  letter-spacing: 0;
  font-weight: 800;
  line-height: 0.9;
  margin-bottom: 40px;
}

.quienes-somos-information {
  color: #9da1a6;
  font-size: 20px;
  font-weight: 400;
  padding-left: 30px;
}

.values-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 60px;
  margin-bottom: 30px;
  margin-right: 20px;
  margin-left: 20px;
  flex-wrap: wrap;
}

.value-container {
  display: flex;
  flex-direction: column;
  max-width: 300px;
}

.value-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.value-img {
  height: 100px;
  width: auto;
}

.values {
  height: 70px;
  width: auto;
}

.value-title {
  color: #0691d1;
  font-size: 50px;
  letter-spacing: 0;
  font-weight: 800;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
  margin-top: 10;
}

.value-description {
  color: #9da1a6;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
}

.home-carousel-2 {
  margin-bottom: 30px;
}

.home-carousel-2-control-btn-left {
  margin-left: 40px !important;
  margin-right: 20px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 380px;
}

.home-carousel-2-control-btn-right {
  margin-right: 40px !important;
  margin-left: 20px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 380px;
}

.history-slider-button {
  height: 60px;
  width: 60px;
  border-radius: 30px;
  background-color: rgb(241, 241, 241);
  border: 0px;
}

.history-slider-button > h2 {
  margin: 0px;
}

.home-carousel-sliders-history {
  height: 400px;
  flex: 1;
}

.que-vendemos-title-image {
  margin-left: 60px;
  height: 70px;
  width: auto;
}

.que-vendemos {
  margin-top: 60px;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 40px;
}

.que-vendemos-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 40px;
}

.que-vendemos-title {
  color: #0691d1;
  font-size: 50px;
  letter-spacing: 0;
  font-weight: 800;
  margin: 0;
  padding: 0;
  line-height: 0.9;
  margin-left: 120px;
}

.que-vendemos-label {
  color: #9da1a6;
  font-size: 26px;
  padding: 70px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: 400;
}

@media (max-width: 1650px) {
  .quienes-somos-slide {
    height: calc(100vh - 300px) !important;
  }
}

@media (max-width: 1350px) {
  .quienes-somos-slide {
    height: calc(100vh - 150px) !important;
  }
}

@media (max-width: 1024px) {
  .quienes-somos-information {
    font-size: 16px;
  }

  .quienes-somos-title-image {
    width: 400px;
  }

  .que-vendemos-title-image {
    margin-left: 0px;
  }

  .value-img {
    height: 60px;
  }

  .quienes-somos-slide {
    height: calc(100vh - 900px) !important;
  }

  .footer-navigation {
    display: none;
  }

  .quienes-somos-title {
    font-size: 35px !important;
  }

  .quienes-somos-content {
    margin-top: 50px !important;
  }

  .que-vendemos {
    margin-top: 20px;
    text-align: center;
  }

  .que-vendemos-title {
    margin-left: 0px;
  }

  .quienes-somos-image {
    margin-bottom: 40px !important;
  }

  .que-vendemos-label {
    padding: 20px !important;
    font-size: 24px !important;
    font-weight: 400 !important;
  }
}

@media (max-width: 991px) {
  .quienes-somos-slide {
    height: calc(100vh - 700px) !important;
  }
}

@media (max-width: 850px) {
  .que-vendemos-container {
    justify-content: space-between !important;
  }
}

@media (max-width: 768px) {
  .quienes-somos-title {
    margin-bottom: 25px;
  }

  .quienes-somos-information {
    font-size: 12px;
  }

  .home-carousel-2 {
    margin-bottom: 0px;
  }

  .quienes-somos-title-image {
    width: 300px;
  }

  .home-carousel-2-img {
    padding: 5px !important;
    padding-left: 0px;
    padding-right: 0px;
  }

  .value-img {
    height: 65px;
  }

  .value-title {
    font-size: 30px;
  }

  .value-description {
    font-size: 12px;
  }

  .history-slider-button {
    height: 50px !important;
    width: 50px !important;
    border-radius: 25px;
  }

  .que-vendemos {
    margin-top: 40px;
  }

  .que-vendemos-title {
    font-size: 30px !important;
  }

  .que-vendemos-label {
    font-size: 16px !important;
    padding: 12px !important;
  }

  .footer-navigation-label {
    font-size: 10px !important;
  }

  .derechos-reservados {
    font-size: 8px !important;
  }
}

@media (max-width: 767px) {
  .home-carousel-sliders-history,
  .home-carousel-2-control-btn-left,
  .home-carousel-2-control-btn-right {
    height: 180px !important;
    margin-top: 10px;
    margin-right: 10px !important;
    margin-left: 10px !important;
  }

  .quienes-somos-container {
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 0px;
    padding-right: 0px;
  }

  .quienes-somos-title-image {
    margin-left: 20px;
    width: 250px;
  }

  .quienes-somos-image-container {
    margin-bottom: 30px;
    width: 100%;
  }

  .quienes-somos-information-container {
    padding-top: 0px;
    text-align: center;
    width: 100%;
  }

  .values-container {
    margin-top: 10px;
  }

  .values {
    height: 35px;
  }

  .que-vendemos-title-image {
    height: 35px;
    width: auto;
  }
}
