.compras-container {
  // height: calc(100vh - 400px);
  iframe {
    height: 100vh;
    width: 100%;

    header {
      display: none !important;
    }
  }
}
