.sucursales-title {
  margin: 0px;
  margin-left: 30px;
  font-size: 60px;
  color: #0691d1;
  font-weight: bold;
}

.branch-offices-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: calc(100vh - 400px);
  margin-bottom: 40px;
}

.sucursal {
  cursor: pointer;
  height: auto;
  min-height: 100px;
  padding: 20px;
  width: 50%;
}

.sucursal:hover {
  opacity: 0.7;
}

@media (max-width: 1024px) {
  .branch-offices-container {
    margin-bottom: 20px;
  }
}

@media (max-width: 991px) {
  .sucursales-title {
    font-size: 30px !important;
  }
}

@media (max-width: 767px) {
  .sucursal {
    padding: 20px;
    padding-top: 0px;
    width: 100%;
  }
}
