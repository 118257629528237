.map {
  margin-bottom: 80px;
  div {
    height: 300px !important;

    .gmnoprint {
      display: none;
    }

    a {
      display: none;
    }

    .gm-control-active {
      display: none;
    }

    .gm-style {
      div {
        :focus {
          outline: 0px !important;
        }
      }
    }
  }
}
