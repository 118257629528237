.modal-close-button {
  position: absolute;
  right: 40px;
  top: 30px;
  border: none;
  background: transparent;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  text-shadow: #000 0px 0px 1px;
}

@media (max-width: 767px) {
  .modal-close-button {
    right: 18px;
    top: 13px;
  }
}
