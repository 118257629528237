.main-modal > .quoatations-container {
  margin-top: 0px;
}

.slide-cotizacion {
  height: 700px !important;
}

.cotizaciones-form-container {
  margin-top: 0px;
  display: flex;
  flex-direction: row;
  margin-right: 5%;
  margin-left: 5%;
  margin-bottom: 40px;
  margin-top: 40px;
  flex-wrap: wrap;
}

input::placeholder {
  color: rgb(157, 161, 166);
  opacity: 1; /* Firefox */
}

textarea::placeholder {
  color: rgb(157, 161, 166);
  opacity: 1; /* Firefox */
}

input {
  color: black;
}

.cotizaciones-form-container-image {
  flex: 1;
  display: flex;
  min-width: 200px;
}

.mensaje-enviado-image {
  width: 800px;
}

@media (max-width: 767px) {
  .mensaje-enviado-image {
    width: 350px;
  }
}

@media (max-width: 1024px) {
  .slide-cotizacion {
    height: 470px !important;
  }
}

@media (max-width: 768px) {
  .slide-cotizacion {
    height: 350px !important;
  }

  .cotizaciones-form-container-image {
    display: none;
  }

  .cotizaciones-form-container {
    margin-top: 40px;
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .slide-cotizacion {
    height: 200px !important;
  }

  .cotizaciones-form-container {
    margin-top: 10px;
  }
}
