body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navigation-link {
  font-size: 24px !important;
  margin: 0px !important;
  margin-right: 40px !important;
  margin-left: 40px !important;
}

.whatsapp-modal-image {
  width: 800px;
}

@media (max-width: 1350px) {
  .footer-navigation-label {
    font-size: 14px !important;
  }
}

@media (max-width: 1300px) {
  .navigation-link {
    font-size: 20px;
  }

  .navigation-msc-logo {
    height: 60px !important;
  }
}

@media (max-width: 1200px) {
  .footer-navigation-link {
    font-size: 12px;
  }
}

@media (max-width: 1100px) {
  .navigation-link {
    font-size: 16px !important;
  }
}

@media (max-width: 1024px) {
  .derechos-reservados {
    font-size: 10px;
  }

  .que-vendemos-label {
    padding: 20px !important;
  }
}

@media (max-width: 991px) {
  .navigation-link {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .navigation-links-container {
    padding-bottom: 10px !important;
  }

  .navigation-msc-logo {
    height: 50px !important;
  }
}

@media (max-width: 850px) {
  .quotations-form {
    margin-left: 0px !important;
  }

  .sucursal-title {
    display: block !important;
  }

  .carousel-sucursal {
    display: none;
  }

  .navigation-container {
    height: 120px !important;
  }

  .navigation-link {
    font-size: 10px;
  }

  .navigation-msc-logo {
    height: 40px !important;
  }
}

@media (max-width: 767px) {
  .whatsapp-modal-image {
    width: 350px;
  }
}

@font-face {
  font-family: "Gotham Book";
  src: url("./fonts/Gotham-Book.eot");
  src: url("./fonts/Gotham-Book.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Gotham-Book.woff2") format("woff2"),
    url("./fonts/Gotham-Book.woff") format("woff"),
    url("./fonts/Gotham-Book.ttf") format("truetype"),
    url("./fonts/Gotham-Book.svg#Gotham-Book") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url("./fonts/Gotham-Medium.eot");
  src: url("./fonts/Gotham-Medium.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Gotham-Medium.woff2") format("woff2"),
    url("./fonts/Gotham-Medium.woff") format("woff"),
    url("./fonts/Gotham-Medium.ttf") format("truetype"),
    url("./fonts/Gotham-Medium.svg#Gotham-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham Ultra";
  src: url("./fonts/Gotham-Ultra.eot");
  src: url("./fonts/Gotham-Ultra.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Gotham-Ultra.woff2") format("woff2"),
    url("./fonts/Gotham-Ultra.woff") format("woff"),
    url("./fonts/Gotham-Ultra.ttf") format("truetype"),
    url("./fonts/Gotham-Ultra.svg#Gotham-Ultra") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  font-family: "Gotham";
}
