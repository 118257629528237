.branch-information-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-right: 10%;
  padding-left: 10%;
  margin-top: 40px;
  margin-bottom: 40px;
  flex-wrap: wrap;
}

.branch-information-title-container {
  background-color: #ffffff;
  margin-top: 40px;
  margin-left: 50px;
}

.branch-information-title {
  margin: 0px;
  margin-left: 40px;
  font-size: 50px;
  color: #0691d1;
  font-weight: bold;
}

.branch-office-information-title-image {
  height: auto;
  width: 200px;
  margin-bottom: 10px;
}

.branch-office-image-title-01 {
  margin-bottom: 30px;
  height: auto;
  width: 700px;
}

.branch-office-image-title-02 {
  margin-bottom: 30px;
  height: auto;
  width: 480px;
}

.branch-office-information-title {
  color: #9da1a6;
  font-size: 34px;
  margin: 0px;
  font-weight: 300;
}

.branch-office-information {
  color: #9da1a6;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.3;
}

@media (max-width: 768px) {
  .branch-information-container {
    flex-direction: column;
    margin-top: 40px;
  }

  .branch-information-title {
    margin-left: 20px;
    margin-right: 20px;
    font-size: 30px;
  }

  .branch-office-information-title {
    font-size: 20px !important;
  }

  .branch-office-information {
    font-size: 16px;
  }

  .branch-information-title-container {
    margin-left: 0px;
    text-align: center;
  }

  .branch-office-information-title-image {
    width: 100px;
  }

  .branch-office-information-title-image-address {
    width: 135px;
  }

  .branch-office-information-title-image-whatsapp {
    width: 135px;
  }
}

@media (max-width: 767px) {
  .branch-office-image-title-01 {
    width: 295px;
  }

  .branch-office-image-title-02 {
    width: 215px;
  }
}
