.wa-wrapper {
  background-color: #f8eb24;
  text-transform: uppercase;
  border-radius: 5%;
  padding: 5px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 3px 3px 2px rgba(0, 0, 0, 0.1);
}

.wa {
  display: flex;
  flex-direction: column;
}

.wa-first-word {
  font-weight: 900;
  color: #0392d1;
  letter-spacing: -1px;
  display: block;
}

.wa-second-word {
  color: #0392d1;
}

@media (max-width: 850px) {
  .wa-wrapper-section {
    display: none;
  }
}
