.overlay-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  z-index: 2;
}

.main-modal {
  position: fixed;
  margin: auto;
  z-index: 3;
}

.wrapper-modal {
  display: flex;
  justify-content: center;
}
